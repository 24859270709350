import Card from '../../components/card/Card';
import ProjectForm from '../../widgets/projectForm';
import FeedbackForm from '../../widgets/feedbackForm';
import ProjectRenderError from '../../widgets/projectRenderError';

import { ProjectContextProvider } from '../../context/projectContext';
import useModal from '../../hooks/useModal';

function ProjectPage() {
	const { isOpen, openModal, closeModal } = useModal();

	return (
		<ProjectContextProvider>
			<Card>
				<ProjectRenderError />
				<ProjectForm openModal={openModal} />
			</Card>
			<FeedbackForm isOpen={isOpen} closeModal={closeModal} />
		</ProjectContextProvider>
	);
};

export default ProjectPage;