import { useTranslation } from 'react-i18next';

import Card from '../../components/card/Card';

import ProfileForm from '../../widgets/profileForm';
import ChangePasswordProfileForm from '../../widgets/changePasswordProfileForm';

function ProfilePage() {
	const { t } = useTranslation();
	
	return (
		<Card>
			<div className="link text-secondary d-flex align-items-center justify-content-center">
				<h3>{t("accountSettings")}</h3>
			</div>
			<ProfileForm />
			<div className="mt-5 link text-secondary d-flex align-items-center justify-content-center">
				<h3>{t("changePassword")}</h3>
			</div>
			<ChangePasswordProfileForm />
		</Card>
	);
}

export default ProfilePage;
