import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../components/card/Card';
import NewProjectSteps from '../../widgets/newProjectSteps';
import NewProjectForm from '../../widgets/newProjectForm';

function NewProjectPage() {
	const [videoClicked, setVideoClicked] = useState(false);
	const [isUploading, setUploading] = useState(false);

	const { t } = useTranslation();

	const handleVideoClicked = () => {
		setVideoClicked(t("chooseAudioAndCreate"));
	};

	return (
		<Card>
			<NewProjectSteps
				isUploading={isUploading}
				videoClicked={videoClicked}
			/>
			<NewProjectForm
				isUploading={isUploading}
				setUploading={setUploading}
				handleVideoClicked={handleVideoClicked}
			/>
		</Card>
	);
}

export default NewProjectPage;