import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import YaShare from '../../components/yaShare';
import SharePopover from '../../components/sharePopover';

import { Share, ShareFill } from 'react-bootstrap-icons';

import ShareClient from '../../api/shareClient';

function ShareVideo({ projectId, videoType }) {
	const [shareLink, setShareLink] = useState("");
	const [isShared, setIsShared] = useState(false);
	
	const shareInputRef = useRef(null);
	const share = useRef(null);
	const getSharePromise = useRef(null);

	const shareClient = useMemo(() => new ShareClient(), []);

	const getShareLink = (share) => `${window.location.origin}/v/${share}`;

	const handleShareVideo = async () => {
		if (projectId == null) return;

		const shareResponse = await shareClient.create(projectId, videoType);
		
		if (shareResponse == null) return;

		const newShareLink = getShareLink(shareResponse.share);

		setIsShared(true);
		setShareLink(newShareLink);

		if (navigator.clipboard && window.isSecureContext) {
			await navigator.clipboard.writeText(newShareLink);
		}
	};

	const copySharedVideo = async () => {
		if (navigator.clipboard && window.isSecureContext) {
			await navigator.clipboard.writeText(shareLink);
		}

		if (shareInputRef.current) {
			shareInputRef.current.select();
		}
	};

	const handleRemoveShare = async () => {
		if (!isShared) return;

		await shareClient.remove(projectId, videoType);

		setShareLink("");
		setIsShared(false);
	};

	const handleGetShare = useCallback(async () => {
		if (!getSharePromise.current && !share.current) {
			const getShare = await shareClient.get(projectId, videoType);

			if (getShare === null) return;

			getSharePromise.current = getShare;

			share.current = getShare;
			if (share.current == null) return;

			setIsShared(true);
			setShareLink(getShareLink(share.current.share));
		}
	}, [projectId, shareClient, videoType]);

	useEffect(() => {
		handleGetShare();
	}, [handleGetShare]);

	return (
		<div className="d-flex justify-content-end" data-type={videoType}>
			<YaShare shareLink={shareLink} isShared={isShared} />
			{
				isShared ? (
					<OverlayTrigger
						trigger="click"
						placement="bottom"
						overlay={
							SharePopover({
								shareLink,
								shareInputRef,
								handleRemoveShare,
								copySharedVideo
							})
						}
					>
						<Button variant="danger" data-bs-custom-class="custom-popover">
							<Share />
						</Button>
					</OverlayTrigger>
				) : (
					<button
						type="button"
						className="btn btn-secondary"
						onClick={handleShareVideo}
					>
						<ShareFill />
					</button>
				)
			}
		</div>
	);
};

export default ShareVideo;
