// * prod.config *
//export class Config {
//    static host = "https://api.videoneuron.ru"
//    static authHost = "https://auth.videoneuron.idesk-test.xyz"
//    static subtitles = "https://subapi.videoneuron.ru";
//    static xml = "https://xmlapi.videoneuron.ru";
//    static bot = "https://tg-warnings.videoneuron.ru";
//}

// * dev.config *
// export class Config {
//     static host = "http://localhost:14025"
//     static authHost = "https://auth.videoneuron.idesk-test.xyz"
//     static subtitles = "http://localhost:14029";
//     static xml = "http://localhost:14030";
//     static bot = "https://tg-warnings.videoneuron.ru";
// }

// * test.config *
 export class Config {
     static host = "https://test-api.videoneuron.ru"
     static authHost = "https://auth.videoneuron.idesk-test.xyz"
     static subtitles = "https://test-subapi.videoneuron.ru";
     static xml = "https://test-xmlapi.videoneuron.ru";
     static bot = "https://tg-warnings.videoneuron.ru";
 }
