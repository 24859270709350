import { useTranslation } from 'react-i18next';

import Card from '../../../components/card/Card';
import RegisterForm from '../../../widgets/registerForm';

function RegisterPage() {
	const { t } = useTranslation();

	return (
		<Card>
			<div className="link text-secondary d-flex align-items-center justify-content-between pb-3">
				<h3>{t("registration")}</h3>
			</div>
			<RegisterForm />
		</Card>
	);
}

export default RegisterPage;