function getPeriodText(lastDate) {
	const lastDateAsMilliseconds = Date.parse(lastDate);
	const diffMilliseconds = Date.now() - lastDateAsMilliseconds;

	if (diffMilliseconds <= 0) {
		return `just now`;
	}

	if (diffMilliseconds <= 60000) {
		const secondsAgo = Math.floor(diffMilliseconds / 1000);
		return `${secondsAgo} s. ago`;
	}

	if (diffMilliseconds <= 3600000) {
		const minsAgo = Math.floor(diffMilliseconds / 60000);
		return `${minsAgo} m. ago`;
	}

	if (diffMilliseconds <= 86400000) {
		const hoursAgo = Math.floor(diffMilliseconds / 3600000);
		return `${hoursAgo} h. ago`;
	}

	const daysAgo = Math.floor(diffMilliseconds / 86400000);

	return `${daysAgo} d. ago`;
}

const timeSpanToTime = (timespan) => {
	const dt = new Date("1970-01-01T" + timespan);
	let time = "";

	const hrs = dt.getHours();
	if (hrs) time += `${hrs} hours `;
	const mins = dt.getMinutes();
	if (mins) time += `${mins} min `;
	const secs = dt.getSeconds();
	if (secs) time += `${secs} sec`;

	return time || "0 sec";
};

const timeSpanToSec = (timespan) => {
	const dt = new Date("1970-01-01T" + timespan);
	const dt0 = new Date("1970-01-01T00:00:00");
	const msecs = dt.getTime() - dt0.getTime();

	return msecs / 1000;
};

const secToTimeSpan = (sec) => {
	if (isNaN(sec)) return null;

	const res = new Date(sec * 1000).toISOString().slice(11, 19);
	
	return res;
};

export {
	getPeriodText,
	timeSpanToTime,
	timeSpanToSec,
	secToTimeSpan
};
