import { useContext } from 'react';

import ProjectBar from '../projectBar';
import ProjectPlayer from '../projectPlayer';
import ProjectControls from '../projectControls';
import ProjectProcessing from '../projectProcessing';
import ProjectButtons from '../projectButtons';
import ProjectFormErrors from '../projectFormError';

import { ProjectContext } from '../../context/projectContext';
import ProjectStatus from '../projectStatus';

function ProjectForm({ openModal }) {
	const context = useContext(ProjectContext);
	const { project, projectName, handleSubmit } = context?.render;

	return (
		project && (
			<>
				<div className="link text-secondary d-flex align-items-center mb-1">
					<h3 className="flex-grow-1">{projectName}</h3>
					<ProjectBar openModal={openModal} />
					<ProjectStatus />
				</div>
				<ProjectPlayer />
				<form onSubmit={handleSubmit} className="row g-3">
					<ProjectControls />
					<ProjectProcessing />
					<ProjectFormErrors />
					<ProjectButtons />
				</form>
			</>
		)
	);
}

export default ProjectForm;
