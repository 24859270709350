import './App.scss';
import '../i18n.js';

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from '../components/layout';
import ProjectListPage from '../pages/projectListPage';
import NewProjectPage from '../pages/newProjectPage';
import ProjectPage from '../pages/projectPage';
import LoginPage from '../pages/auth/loginPage';
import RegisterPage from '../pages/auth/registerPage';
import ProfilePage from '../pages/profilePage';
import SharedPage from '../pages/sharedPage';
import FaqPage from '../pages/faqPage';
import ResetPasswordPage from '../pages/auth/resetPasswordPage';
// import EditorPage from '../pages/editorPage';

import { AuthProtectedRoute, MainProtectedRoute } from '../pages/auth/protectedRoutes';

import { getUser } from '../store/slices/userSlice';

import AuthHelper from '../helpers/authHelper.js';

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.jwt);
  
  const authHelper = useMemo(() => new AuthHelper(), []);

  // script for yandex share
  useEffect(() => {
    const script = document.createElement("script");
    
    script.src = "https://yastatic.net/share2/share.js";
    script.async = true;
    script.crossOrigin = "anonymous";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    if (token && authHelper.checkToken(token)) {
      dispatch(getUser());
    }
  }, [authHelper, dispatch, token]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route
            path="/"
            element={<MainProtectedRoute children={<ProjectListPage />} />}
          />
          <Route
            path="/project"
            element={<MainProtectedRoute children={<NewProjectPage />} />}
          />
          <Route
            path="/project/:id"
            element={<MainProtectedRoute children={<ProjectPage />} />}
          />
          <Route
            path="/profile"
            element={<MainProtectedRoute children={<ProfilePage />} />}
          />
          {/* <Route
            path="/editor/:projectId"
            element={<MainProtectedRoute children={<EditorPage />} />}
          /> */}
          <Route
            path="/login"
            element={<AuthProtectedRoute children={<LoginPage />} />}
          />
          <Route
            path="/sign-up"
            element={<AuthProtectedRoute children={<RegisterPage />} />}
          />
          <Route path="/v/:share" element={<SharedPage />} />
          <Route path="/faq" element={<FaqPage />}/> 
          <Route path="/changePassword" element={<ResetPasswordPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;